import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input, Badge, Tag } from "antd";
import { CheckSquareOutlined, CheckSquareFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
// Components
import Wrapper from "../components/wrapper";

import ServerSideTable from "../components/table/serverSideTable";
import NotificationModal from "../components/notification/modal";

// Actions
// import { toggleModal, setForm } from "../redux/actions/users";
import {
  fetchAllNotification,
  toggleModal,
} from "../redux/actions/notification";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const Notification = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    notificationData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ notificationReducer }) => ({
      notificationData: notificationReducer.data,
      totalRecord: notificationReducer.totalRecord,
      nextPage: notificationReducer.nextPage,
      previousPage: notificationReducer.previousPage,
      currentPage: notificationReducer.currentPage,
      loading: notificationReducer.loading,
      formLoading: notificationReducer.formLoading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllNotification(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => dispatch(toggleModal());

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllNotification(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllNotification({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllNotification({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllNotification({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllNotification({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        sort: { field: "created_at", order: "desc" },
      })
    );
  };

  useEffect(() => {
    dispatch(fetchAllNotification(defaultPage));
  }, [dispatch, defaultPage]);

  const columns = useMemo(
    () => [
      //   {
      //     Header: "Actions",
      //     Cell: ({ row: { original } }) => (
      //       <>
      //         <Button
      //           //   disabled={formLoading}
      //           onClick={() => {
      //             onCheck(original);
      //           }}
      //           size="small"
      //           type="text"
      //         >
      //           <Tooltip title="Verified">
      //             <CheckSquareOutlined />
      //           </Tooltip>
      //         </Button>
      //       </>
      //     ),
      //     width: 100,
      //   },
      // {
      //   Header: "App Name",
      //   accessor: "appName",
      // },
      {
        Header: "Notification Title",
        accessor: "notification_title",
      },
      {
        Header: "Notification Message",
        accessor: "notification_message",
      },
      {
        Header: "Municipality",
        accessor: "municipality",
        Cell: ({ value }) => {
          return JSON.parse(value);
        },
      },
      {
        Header: "Sent",
        accessor: "sent_count",
      },

      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("YYYY-MM-DD");
        },
      },
    ],
    []
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        {/* <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="Enter ticket number or first name or last name"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="small"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={() => {}}
              />
            </Form.Item>
          </Form>
        </div> */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <h1 style={{ fontSize: 30 }}>NOTIFICATION</h1>
        <ServerSideTable
          columns={columns}
          data={notificationData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <NotificationModal />
          </>
        }
      />
    </>
  );
};

export default Notification;
