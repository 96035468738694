import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";

import {
  Layout,
  Button,
  Form,
  Input,
  Popconfirm,
  Tooltip,
  Tag,
  Switch,
  Badge,
} from "antd";
import {
  PlusOutlined,
  CopyOutlined,
  LeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import MenuModal from "../components/merchantMenu/modal";
import VariantModal from "../components/merchantMenu/variantModal";
import DuplicateModal from "../components/merchantMenu/duplicateModal";

import {
  toggleDuplicateModal,
  toggleModal,
  setForm,
  fetch,
  softDeletionMenu,
  updateMenuStatus,
  //   toggleImportModal,
} from "../redux/actions/merchantMenu";

import formatCurrency from "../helpers/formatCurrency";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const MerchantMenu = () => {
  const dispatch = useDispatch();
  const { merchantId, merchantName, merchantTypeId } = useParams();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [status, setStatus] = useState(null);
  const [variantModalVisible, setVariantModalVisible] = useState(false);
  const [typeId, setTypeId] = useState(null);
  const history = useHistory();
  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    data,
    form,
    modalDuplicate,
    userInfo,
  } = useSelector(
    ({ MerchantMenuReducer, userLoginReducer }) => ({
      data: MerchantMenuReducer.data,
      loading: MerchantMenuReducer.loading,
      formLoading: MerchantMenuReducer.formLoading,
      totalRecord: MerchantMenuReducer.totalRecord,
      nextPage: MerchantMenuReducer.nextPage,
      previousPage: MerchantMenuReducer.previousPage,
      currentPage: MerchantMenuReducer.currentPage,
      form: MerchantMenuReducer.form,
      modalDuplicate: MerchantMenuReducer.modalDuplicate,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  useEffect(() => {
    if (!hasForm) {
      setVariantModalVisible(false);
    }
  }, [hasForm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      searchText: delaySearchText,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch, merchantId]);

  const onAdd = () => {
    dispatch(toggleModal());
    setVariantModalVisible(false);
  };

  const onDuplicate = () => {
    dispatch(toggleDuplicateModal());
    setVariantModalVisible(false);
  };

  //   const onClickBulkUpload = () => {
  //     dispatch(toggleImportModal());
  //   };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      userId: merchantId,
    };

    dispatch(fetch(payload));
  };
  const onEditVariants = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
      setVariantModalVisible(true);
    },
    [dispatch]
  );

  const onEdit = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
      setTypeId(merchantTypeId);
    },
    [dispatch]
  );

  const goBack = () => {
    history.goBack();
  };

  const softDelete = (payload) => {
    dispatch(softDeletionMenu(payload));
  };

  // <<<<<<< GSTOR-153
  //   const softDelete = (payload) => {
  //     dispatch(softDeletionMenu(payload));
  //   };

  //   const customFilters = () => {
  //     return (
  //       <div className="mb-2">
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             gap: 10,
  //             alignItems: "baseline",
  //             marginLeft: 10,
  //           }}
  //         >
  //           <LeftOutlined
  //             onClick={goBack}
  //             style={{
  //               fontSize: 20,
  //               color: "gray",
  //             }}
  //           />
  //           <h1 onClick={goBack} style={{ fontSize: 23, color: "gray" }}>
  //             {merchantName?.toUpperCase()} MENUS
  //           </h1>
  //         </div>
  // =======
  //  // const onView = ({ data }) => {
  //  //   setViewMerchantsDetails(true);
  //  //   dispatch(setForm(data));
  //  //   dispatch(toggleModal());
  //  // };
  // >>>>>>> main

  const setMenuStatus = async (status, id) => {
    dispatch(updateMenuStatus({ status, id }));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "baseline",
            marginLeft: 10,
          }}
        >
          <LeftOutlined
            onClick={goBack}
            style={{
              fontSize: 20,
              color: "gray",
            }}
          />
          <h1 onClick={goBack} style={{ fontSize: 23, color: "gray" }}>
            {merchantName?.toUpperCase()} MENUS
          </h1>
        </div>

        {/* <h1>Merchant Menu - {`${city} ${province}`}</h1> */}
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  //   const extra = () => {
  //     const addButton = (
  //       <Button key="1" onClick={onAdd} className="mr-2">
  //         <PlusOutlined /> Add
  //       </Button>
  //     );

  //     return [addButton];
  //   };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: merchantId,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
        userId: merchantId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
        userId: merchantId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
        userId: merchantId,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: merchantId,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            {/* {original.Size === null || original.Size === "" ? ( */}

            {userInfo.role === "operator_admin" && (
              <>
                <Tooltip title="Deactivate / Activate">
                  <Switch
                    disabled={formLoading}
                    size="medium"
                    defaultChecked={original.isActive === 1}
                    onChange={(checked) => {
                      setMenuStatus(checked, original.id);
                    }}
                  />
                </Tooltip>
                {/* <Popconfirm
                  disabled={formLoading}
                  title="Delete"
                  description="Are you sure you want to Delete this menu item?"
                  onConfirm={() => {
                    softDelete({ data: original, merchantId: merchantId });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small">Delete</Button>
                </Popconfirm> */}
              </>
            )}

            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
            >
              Edit
            </Button>
            {/*  ) : (*/}
            {/* <Button
             disabled={formLoading}
             onClick={() => onEditVariants({ data: original })}
             size="small"
           >
             Edit Variants
           </Button> */}
            {/* // )}*/}
          </div>
        ),
        width: 100,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => {
          return value === 1 ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Deactivated" />
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Category",
        accessor: "category_title",
      },
      {
        Header: "Price",
        Cell: ({ row: { original } }) => {
          let data = [];
          if (original.Size !== null && original.Size !== "") {
            data = JSON.parse(JSON.parse(JSON.stringify(original.Size)));
          }
          return (
            <>
              {data.length ? (
                data.map((d) => (
                  <Tag>{`${d["variant"]}: ${formatCurrency(d["price"])}`}</Tag>
                ))
              ) : (
                <Tag>{formatCurrency(original.price)}</Tag>
              )}
            </>
          );
        },
      },
    ],
    [formLoading, onEdit]
  );

  useEffect(() => {
    dispatch(fetch(defaultPage));
  }, [dispatch, defaultPage, modalDuplicate]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
            // <Button key="1" onClick={onDuplicate} className="mr-2">
            //   <CopyOutlined /> Duplicate menu
            // </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      {variantModalVisible ? (
        <Wrapper
          content={renderContent()}
          modal={
            <>
              <VariantModal />
              <DuplicateModal />
            </>
          }
        />
      ) : (
        <Wrapper
          content={renderContent()}
          modal={
            <>
              <MenuModal merchantTypeId={typeId} />
              {/* <DuplicateModal /> */}
            </>
          }
        />
      )}
      {/* <Wrapper
       content={renderContent()}
       modal={
         <>
           <MenuModal />
           {variantModalVisible && <VariantModal />}
         </>
       }
     /> */}
    </>
  );
};

export default MerchantMenu;
