import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Select,
  Switch,
  Popconfirm,
  DatePicker,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import {
  PlusOutlined,
  FormOutlined,
  MenuOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import {
  fetchAllAdminAccounts,
  toggleModal,
  setForm,
} from "../redux/actions/adminAccount";

import CreateAdminAccount from "../components/createAdminAccount/createAdminAccountModal";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const CreateAccount = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [isApproved, setIsApproved] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const { RangePicker } = DatePicker;

  const {
    loading,
    adminAccounts,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    formLoading,
  } = useSelector(
    ({ adminAccountReducer, userLoginReducer }) => ({
      adminAccounts: adminAccountReducer.data,
      totalRecord: adminAccountReducer.totalRecord,
      nextPage: adminAccountReducer.nextPage,
      previousPage: adminAccountReducer.previousPage,
      currentPage: adminAccountReducer.currentPage,
      loading: adminAccountReducer.loading,
      formLoading: adminAccountReducer.formLoading,
    }),
    isEqual
  );

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllAdminAccounts(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllAdminAccounts({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllAdminAccounts({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllAdminAccounts({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllAdminAccounts({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
    };
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      isApproved,
      isVerified,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(fetchAllAdminAccounts(payload));
    } else {
      dispatch(fetchAllAdminAccounts(i));
    }
  }, [
    delaySearchText,
    toSelectedDate,
    selectedDate,
    dispatch,
    isApproved,
    isVerified,
  ]);
  const onEdit = ({ data }) => {
    dispatch(setForm(data));
    dispatch(toggleModal());
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div className="flex gap-2">
            <div>
              <Button
                disabled={formLoading}
                onClick={() => onEdit({ data: original })}
                size="small"
                type="text"
              >
                <Tooltip title="Edit">
                  <FormOutlined />
                </Tooltip>
              </Button>

              <Link to={`/approved-partner-payables/${original.id}`}>
                <Button disabled={formLoading} size="small" type="text">
                  <Tooltip title="Partner Payables">
                    <DollarCircleOutlined />
                  </Tooltip>
                </Button>
              </Link>
            </div>
          </div>
        ),
        width: 300,
      },

      {
        Header: "Account Role",
        accessor: "role",
      },
      {
        Header: "User Name",
        accessor: "user_name",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Created date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at).tz("Asia/Manila");
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
    ],
    []
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Admin Accounts</h1>
        <div
          className="p-flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            {/* <RangePicker
              style={{ width: "30vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            /> */}
          </Form>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={adminAccounts}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Create admin account
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <CreateAdminAccount />
          </>
        }
      />
    </>
  );
};

export default CreateAccount;
