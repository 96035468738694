import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Select,
  Segmented,
  ConfigProvider,
  Typography,
  Alert,
  Space,
  Button,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";

import { isEqual } from "lodash";
import formatCurrency from "../helpers/formatCurrency";

// Components
import Wrapper from "../components/wrapper";

import { Line, Bar } from "react-chartjs-2";

import {
  fetchPartnerDashBoardCollectedDeliveryFee,
  fetchPartnerDashBoardTransactions,
  fetchPartnerDashBoardTopMerchants,
  fetchPartnerCustomerGrowth,
} from "../redux/actions/partnerDashBoard";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Content: LayoutContent } = Layout;
const { Title } = Typography;

const PartnerDashBoard = () => {
  const dispatch = useDispatch();

  const {
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
    totalCollectedDeliveryFee,
    topMerchants,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
  } = useSelector(
    ({ PartnerDashBoardReducer }) => ({
      dailyTransactionData: PartnerDashBoardReducer.dailyTransactionData,
      weeklyTransactionData: PartnerDashBoardReducer.weeklyTransactionData,
      monthlyTransactionData: PartnerDashBoardReducer.monthlyTransactionData,
      totalCollectedDeliveryFee: PartnerDashBoardReducer.collectedDeliveryFee,
      topMerchants: PartnerDashBoardReducer.topMerchants,
      dailyCustomerGrowthData: PartnerDashBoardReducer.dailyCustomerGrowthData,
      weeklyCustomerGrowthData:
        PartnerDashBoardReducer.weeklyCustomerGrowthData,
      monthlyCustomerGrowthData:
        PartnerDashBoardReducer.monthlyCustomerGrowthData,
    }),
    isEqual
  );

  const [status, setStatus] = useState("delivered");
  const [timeFrame, setTimeFrame] = useState("Daily");
  const [transactionGraphData, setTransactionGraphData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateTopMerchants, setFromDateTopMerchants] = useState(null);
  const [toDateTopMerchants, setToDateTopMerchants] = useState(null);
  const [customerGrowthGraphData, setCustomerGrowthGraphData] = useState([]);
  const [customerGrowthTimeFrame, setCustomerGrowthTimeFrame] =
    useState("Daily");

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };

  const handleRangeChangeTopMerchants = (dates) => {
    if (dates && dates.length === 2) {
      setFromDateTopMerchants(dates[0]);
      setToDateTopMerchants(dates[1]);
    } else {
      setFromDateTopMerchants(null);
      setToDateTopMerchants(null);
    }
  };

  useEffect(() => {
    if (customerGrowthTimeFrame === "Daily") {
      setCustomerGrowthGraphData(dailyCustomerGrowthData);
    }

    if (customerGrowthTimeFrame === "Weekly") {
      setCustomerGrowthGraphData(weeklyCustomerGrowthData);
    }
    if (customerGrowthTimeFrame === "Monthly") {
      setCustomerGrowthGraphData(monthlyCustomerGrowthData);
    }
  }, [
    customerGrowthTimeFrame,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
  ]);

  useEffect(() => {
    if (timeFrame === "Daily") {
      setTransactionGraphData(dailyTransactionData);
    }

    if (timeFrame === "Weekly") {
      setTransactionGraphData(weeklyTransactionData);
    }
    if (timeFrame === "Monthly") {
      setTransactionGraphData(monthlyTransactionData);
    }
  }, [
    timeFrame,
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
  ]);

  const handleChange = (value) => {
    setStatus(value);
  };

  //transaction
  const defaultPage = useMemo(() => {
    return {
      status,
      timeFrame,
    };
  }, [status, timeFrame]);

  // customer growth
  const defaultPageCustomerGrowth = useMemo(() => {
    return {
      timeFrame: customerGrowthTimeFrame,
    };
  }, [customerGrowthTimeFrame]);

  useEffect(() => {
    dispatch(fetchPartnerDashBoardTransactions(defaultPage));
  }, [dispatch, defaultPage, status]);

  useEffect(() => {
    dispatch(fetchPartnerCustomerGrowth(defaultPageCustomerGrowth));
  }, [dispatch, defaultPageCustomerGrowth]);

  useEffect(() => {
    let payload = { defaultDate: true };
    if (fromDate !== null && toDate !== null) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDate) === "Invalid Date"
            ? undefined
            : formatDate(fromDate),
        toDate:
          formatDate(toDate) === "Invalid Date"
            ? undefined
            : formatDate(toDate),
        defaultDate: false,
      };
    }

    dispatch(fetchPartnerDashBoardCollectedDeliveryFee(payload));
  }, [dispatch, fromDate, toDate]);

  useEffect(() => {
    let payload = { defaultDate: true };
    if (fromDateTopMerchants !== null && toDateTopMerchants !== null) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDateTopMerchants) === "Invalid Date"
            ? undefined
            : formatDate(fromDateTopMerchants),
        toDate:
          formatDate(toDateTopMerchants) === "Invalid Date"
            ? undefined
            : formatDate(toDateTopMerchants),
        defaultDate: false,
      };
    }

    dispatch(fetchPartnerDashBoardTopMerchants(payload));
  }, [dispatch, fromDateTopMerchants, toDateTopMerchants]);

  const Content = () => {
    return (
      <LayoutContent>
        <Alert
          style={{ marginLeft: 38, marginRight: 38 }}
          message={
            <p className="mt-3">
              <b> Must read:</b> Please go to the "Get Started" section to learn
              more about this platform before proceeding to navigate this system
              or click the button
            </p>
          }
          type="warning"
          showIcon
          closable
          action={
            <Space direction="vertical">
              <Link to={`/getstarted`}>
                <Button size="small">Learn more about Dory Platform</Button>
              </Link>
            </Space>
          }
        />
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setTimeFrame(value);
                  }}
                  value={timeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
            <div>
              <Select
                defaultValue={status}
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "delivered",
                    label: "Delivered",
                  },
                  {
                    value: "undelivered",
                    label: "Undelivered",
                  },
                  {
                    value: "cancelled",
                    label: "Cancelled",
                  },
                ]}
              />
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: transactionGraphData.map((data) => data?.order_date),
                datasets: [
                  {
                    label: "Transaction",
                    data: transactionGraphData.map(
                      (data) => data?.transaction_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Transactions",
                    font: { size: 20 },
                  },
                },
                scales: {
                  y: {
                    title: {
                      color: "red",
                      display: true,
                      text: "No. of transaction",
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",

            margin: 40,
            gap: 30,
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <div
              style={{
                height: "40vh",
                width: "30vw",
                textAlign: "center",
                border: "1px solid #e8e8e8",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div style={{ display: "flex" }}>
                <RangePicker
                  onChange={handleRangeChange}
                  value={[fromDate, toDate]}
                />
              </div>
              <div style={{ alignContent: "center", paddingTop: 40 }}>
                <Title level={3} type="secondary">
                  Total Collected Delivery fee
                </Title>
                <Title level={2}>
                  {formatCurrency(totalCollectedDeliveryFee)}
                </Title>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "40vh",
              width: "50vw",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
              padding: "12px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/*  */}
            <RangePicker
              onChange={handleRangeChangeTopMerchants}
              value={[fromDateTopMerchants, toDateTopMerchants]}
            />
            {/*  */}
            <Bar
              data={{
                labels: topMerchants.map((data) => data?.name),

                datasets: [
                  {
                    label: "total transaction: ",
                    data: topMerchants.map((data) => data?.order_count),
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 205, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 64, 0.2)",
                      "rgba(205, 92, 92, 0.2)",
                      "rgba(0, 206, 209, 0.2)",
                      "rgba(128, 0, 128, 0.2)",
                      "rgba(32, 178, 170, 0.2)",
                    ],
                    borderColor: [
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255, 205, 86)",
                      "rgb(75, 192, 192)",
                      "rgb(153, 102, 255)",
                      "rgb(255, 159, 64)",
                      "rgb(205, 92, 92)",
                      "rgb(0, 206, 209)",
                      "rgb(128, 0, 128)",
                      "rgb(32, 178, 170)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                height: "30vh",
                indexAxis: "y",
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Top 10 Merchants",
                    font: { size: 20 },
                  },
                },
                layout: {
                  padding: {
                    left: 30,
                    right: 30,
                    bottom: 30,
                  },
                },
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setCustomerGrowthTimeFrame(value);
                  }}
                  value={customerGrowthTimeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: customerGrowthGraphData.map(
                  (data) => data?.register_date
                ),
                datasets: [
                  {
                    label: "new customer",
                    data: customerGrowthGraphData.map(
                      (data) => data?.customer_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Customer Growth",
                    font: { size: 20 },
                  },
                },
              }}
            />
          </div>
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default PartnerDashBoard;
