import React from "react";

import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Layout, Menu, Tooltip, Spin } from "antd";
import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  BellOutlined,
  CalendarOutlined,
  MoneyCollectOutlined,
  DollarOutlined,
  WalletOutlined,
  SmileOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import Icon from "@mdi/react";
import {
  mdiUpdate,
  mdiCashMultiple,
  mdiHandCoinOutline,
  mdiGauge,
} from "@mdi/js";

const { SubMenu } = Menu;
const { Sider } = Layout;

const MainClientSidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const { userInfo } = useSelector(({ userLoginReducer }) => ({
    userInfo: userLoginReducer.userInfo,
  }));

  const renderMenuItem = (title, pathname, icon = "") => {
    // if (userInfo?.userType === "ClientSubAccount") {
    //   if (accessModules.includes(pathname.replace("/", ""))) {
    //     return (
    //       <Menu.Item key={pathname} icon={icon}>
    //         <Link to={pathname}>{title}</Link>
    //       </Menu.Item>
    //     );
    //   }
    // } else {
    // return (
    //   <Menu.Item key={pathname} icon={icon}>
    //     <Link to={pathname}>{title}</Link>
    //   </Menu.Item>
    // );
    return (
      <Menu.Item key={pathname} icon={icon}>
        <Tooltip title={title}>
          <Link to={pathname}>{title}</Link>
        </Tooltip>
      </Menu.Item>
    );
    // }
  };

  const renderSubMenu = (title, icon, submenuItems) => {
    return (
      <SubMenu key={title} icon={icon} title={title}>
        {submenuItems.map((item) => (
          <Menu.Item key={item.pathname}>
            <Link to={item.pathname}>{item.title}</Link>
          </Menu.Item>
        ))}
      </SubMenu>
    );
  };

  const menus = () => {
    return (
      <>
        {userInfo.role === "operator_admin" && (
          <>
            {renderMenuItem(
              "Trainings",
              "/getstarted",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <SmileOutlined />
              </span>
            )}
          </>
        )}
        {renderMenuItem("Bookings", "/bookings", <CalendarOutlined />)}
        {userInfo.role !== "operator_admin" &&
          renderMenuItem(
            "Dashboard",
            "/admin-dashboard",
            <DashboardOutlined />
          )}
        {userInfo.role === "operator_admin" &&
          renderMenuItem(
            "Dashboard",
            "/partner-dashboard",
            <DashboardOutlined />
          )}
        <SubMenu
          icon={<ShopOutlined />}
          // title="Merchants Management"
          title={
            <Tooltip title="Merchants Management">
              <span>Merchants </span>
            </Tooltip>
          }
          key={"partners-products"}
        >
          {renderMenuItem("Area Merchants", "/users", <ShopOutlined />)}

          {userInfo?.role === "admin" && (
            <>
              {renderMenuItem(
                "National Merchants",
                "/national-merchants",
                <ShopOutlined />
              )}
              {renderMenuItem(
                "Merchants tags",
                "/merchant-tags",
                <ShopOutlined />
              )}
              {renderMenuItem(
                "Partner Stores",
                "/partnerstores",
                <ShopOutlined />
              )}

              {renderMenuItem("All Producst", "/allproducts", <ShopOutlined />)}

              {/* test */}
              {renderMenuItem(
                "Merchants For Approval",
                "/merchants-for-approval",
                <ShopOutlined />
              )}
              {renderMenuItem(
                "Merchants Menu For Approval",
                "/merchants-menu-for-approval",
                <ShopOutlined />
              )}
              {/* test */}
            </>
          )}
          {/* {renderMenuItem(
            "Merchants in My Area (Beta)",
            "/local-merchant-in-my-area",
            <ShopOutlined />
          )} */}

          {renderMenuItem(
            "Referrals",
            "/referred-local-merchant",
            <ShopOutlined />
          )}
        </SubMenu>
        <SubMenu
          icon={<UserOutlined />}
          // title="Riders Management"
          title={
            <Tooltip title="Riders Management">
              <span>Riders</span>
            </Tooltip>
          }
          key={"operators"}
        >
          {/* {renderMenuItem("Riders", "/operator-approval", <UserOutlined />)} */}
          {userInfo?.role === "operator_admin" &&
            renderMenuItem("Area Riders", "/rider-my-area", <UserOutlined />)}
          {userInfo?.role === "operator_admin" &&
            renderMenuItem("Referrals", "/referred-rider", <UserOutlined />)}

          {/* {renderMenuItem(
            "Rider Performance",
            "/operatorperformace",
            <DashboardOutlined />
          )} */}
          {userInfo?.role === "admin" && (
            <>
              {/* {renderMenuItem(
                "Operator Performace",
                "/operatorperformace",
                <DashboardOutlined />
              )} */}

              {/* {renderMenuItem(

                "Operator Payables",
                "/accounts-receivable",
                <span
                  role="img"
                  aria-label="money-collect"
                  className="anticon anticon-money-collect ant-menu-item-icon"
                >
                  <Icon
                    path={mdiHandCoinOutline}
                    title="merchant Withdraw request"
                    size="1rem"
                  />
                </span>
              )} */}

              {renderMenuItem(
                "Price Approval",
                "/price-approval",
                <span
                  role="img"
                  aria-label="money-collect"
                  className="anticon anticon-money-collect ant-menu-item-icon"
                >
                  <Icon path={mdiUpdate} title="Update" size="1rem" />
                </span>
              )}
            </>
          )}
        </SubMenu>
        {/* {userInfo?.role === "admin" && (
          <SubMenu
            icon={
              <Icon
                path={mdiCashMultiple}
                title="Operator Withdraw request"
                key={"operator-Withdraw-request"}
                size="1rem"
              />
            }
            title="Accounts Payables"
          >
            {renderMenuItem(
              "Operator Withdraw request",
              "/operator-withdrawal-list",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon
                  path={mdiCashMultiple}
                  title="Operator Withdraw request"
                  size="1rem"
                />
              </span>
            )}
            {renderMenuItem(
              "Merchant Withdraw request",
              "/merchant-withdrawal-list",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon
                  path={mdiCashMultiple}
                  title="merchant Withdraw request"
                  size="1rem"
                />
              </span>
            )}
          </SubMenu>
        )} */}
        {/* {userInfo?.role === "admin" && (
          <>
            {renderMenuItem(
              "Accounts Receivable",
              "/operator-payables",
              <MoneyCollectOutlined />
            )}
            {renderMenuItem(
              "Raffle Tickets",
              "/raffletickets",
              <UserOutlined />
            )}
          </>
        )} */}
        <SubMenu icon={<ShopOutlined />} title="Customers" key={"customers"}>
          {renderMenuItem("Area Customers", "/customer-list", <UserOutlined />)}
          {renderMenuItem("Referrals", "/referred-customer", <UserOutlined />)}
        </SubMenu>

        {/* {userInfo?.role === "operator_admin" && (
          <>
            {renderMenuItem(
              "Finance",
              "/franchisee-payables",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <WalletOutlined />
              </span>
            )}
          </>
        )} */}

        {/* {userInfo?.role === "operator_admin" && (
          <>
            {renderMenuItem(
              "Payable Status",
              "/payables-status",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )}
          </>
        )} */}
        <SubMenu
          icon={<ShopOutlined />}
          title="Marketing tools"
          key={"marketing-tools"}
        >
          {renderMenuItem("Notification", "/notification", <BellOutlined />)}

          {renderMenuItem(
            "Coupons",
            "/partner-coupon",
            <span
              role="img"
              aria-label="money-collect"
              className="anticon anticon-money-collect ant-menu-item-icon"
            >
              <DollarOutlined />
            </span>
          )}
        </SubMenu>

        {userInfo?.role === "admin" && (
          <>
            {renderMenuItem(
              "Partner accounts",
              "/create-account",
              <UserOutlined />
            )}
            {/* {renderMenuItem(

              "Grouped Areas",
              "/grouped-areas",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )} */}
          </>
        )}

        {userInfo?.role === "admin" && (
          <>
            {renderMenuItem(
              "Coupons",
              "/coupons",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )}
          </>
        )}
        {/* {userInfo?.role === "admin" && (
          <>
            {renderMenuItem(
              "All Franchisee Payables",
              "/approved-franchisee-payables",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )}
          </>
        )} */}

        {userInfo?.role === "operator_admin" && (
          <>
            <>
              {/* {renderMenuItem(
                "Push Notifaction",
                "/partner-notifaction",
                <span
                  role="img"
                  aria-label="money-collect"
                  className="anticon anticon-money-collect ant-menu-item-icon"
                >
                  <BellOutlined />
                </span>
              )} */}
            </>
            <></>
          </>
        )}

        <SubMenu icon={<SettingOutlined />} title="Settings" key={"settings"}>
          {userInfo.role === "operator_admin" &&
            renderMenuItem("Account", "/account", <UserOutlined />)}
          {userInfo?.role === "operator_admin" &&
            renderMenuItem(
              "Delivery Fee Settings",
              "/delivery-fee-settings",
              <Icon path={mdiGauge} title="Update" size="1rem" />
            )}
          {(userInfo.user_name === "D03007" ||
            userInfo.user_name === "Main0001" ||
            userInfo.user_name === "D06223" ||
            userInfo.user_name === "D06162") && (
            <>
              {renderMenuItem(
                "Fix rates",
                "/fix-rates",
                <span
                  role="img"
                  aria-label="money-collect"
                  className="anticon anticon-money-collect ant-menu-item-icon"
                >
                  <Icon path={mdiGauge} title="Update" size="1rem" />
                </span>
              )}
            </>
          )}
        </SubMenu>

        {userInfo?.role === "operator_admin" && (
          <>
            {renderMenuItem(
              "Finance",
              "/partner-finance",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )}
          </>
        )}
        {userInfo?.role === "admin" && (
          <>
            {renderMenuItem(
              "Delivery Fee Settings",
              "/succeeding-rate",
              <span
                role="img"
                aria-label="money-collect"
                className="anticon anticon-money-collect ant-menu-item-icon"
              >
                <Icon path={mdiGauge} title="Update" size="1rem" />
              </span>
            )}
          </>
        )}

        {userInfo?.role === "admin" &&
          renderMenuItem(
            "Monitoring",
            "/payables-monitoring",
            <Icon path={mdiGauge} title="Update" size="1rem" />
          )}

        {/*  */}
        {userInfo?.role === "admin" &&
          renderMenuItem(
            "Rider Packages",
            "/rider-availed-package",
            <Icon path={mdiGauge} title="Update" size="1rem" />
          )}

        {userInfo?.role === "admin" &&
          renderMenuItem(
            "New Riders",
            "/new-riders",
            <Icon path={mdiGauge} title="Update" size="1rem" />
          )}

        {userInfo?.role === "admin" &&
          renderMenuItem(
            "Reported Riders",
            "/validate-reported-riders",
            <Icon path={mdiGauge} title="Update" size="1rem" />
          )}
        {userInfo?.role === "admin" &&
          renderMenuItem(
            "Riders Documents",
            "/riders-documents",
            <Icon path={mdiGauge} title="Update" size="1rem" />
          )}
        {/*  */}
      </>
    );
  };

  return (
    <>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        {/* <div className="logo" /> */}

        <div className="items-center pt-4 px-4">
          <h1
            style={{ fontWeight: "bold", fontSize: 34, color: "#38a34a" }}
            className="text-center"
          >
            Dory Platform
          </h1>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          // defaultOpenKeys={[
          //   "partners-products",
          //   "operators",
          //   "operator-Withdraw-request",
          // ]}
        >
          {menus()}
        </Menu>
      </Sider>
    </>
  );
};

export default MainClientSidebar;
