import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Image,
  Popconfirm,
  notification,
  Tag,
} from "antd";
import {
  CheckSquareOutlined,
  PlusOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import RejectMerchantModal from "../components/localMerchantApproval/modal";
// Actions

import moment from "moment/moment";
import {
  fetchMerchantsForApproval,
  toggleModal,
  setForm,
  updateMerchantApprovalStatus,
  toggleOldAccountModal,
} from "../redux/actions/localMerchantsApproval";
import OldAccountMOdal from "../components/oldMerchantAccounts/oldAccountModal";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const MerchantsForApproval = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [newMerchant, setNewmerchant] = useState(null);
  const [newMerchantId, setNewmerchantId] = useState(null);
  const [closeloading, setCloseLoading] = useState(false);
  const {
    loading,
    formLoading,
    merchantsForApprovalData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    oldAccountModal,
  } = useSelector(
    ({ localMerchantForApprovalReducer }) => ({
      merchantsForApprovalData: localMerchantForApprovalReducer.data,
      totalRecord: localMerchantForApprovalReducer.totalRecord,
      nextPage: localMerchantForApprovalReducer.nextPage,
      previousPage: localMerchantForApprovalReducer.previousPage,
      currentPage: localMerchantForApprovalReducer.currentPage,
      loading: localMerchantForApprovalReducer.loading,
      formLoading: localMerchantForApprovalReducer.formLoading,
      oldAccountModal: localMerchantForApprovalReducer.oldAccountModal,
    }),
    isEqual
  );

  useEffect(() => {
    if (!oldAccountModal) {
      setCloseLoading(true);
    }
  }, [oldAccountModal]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchMerchantsForApproval(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchMerchantsForApproval(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 35 }}>Merchants for Approval</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchMerchantsForApproval({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchMerchantsForApproval({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchMerchantsForApproval({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchMerchantsForApproval({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };

  const onReject = (payload) => {
    dispatch(setForm(payload));
    dispatch(toggleModal());
  };

  const onRetainOldAccoumt = (data) => {
    dispatch(toggleOldAccountModal());
    setNewmerchant(data.name);
    setNewmerchantId(data.id);
  };
  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onApproved = async (value) => {
    try {
      const { data } = await dispatch(
        updateMerchantApprovalStatus({ isApproved: 1, id: value.id })
      );

      if (data) {
        openNotification("SUCCESS", "Succesfully Approved");
      }
    } catch (error) {
      console.log(">>>> error approving merchant");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              size="small"
              type="text"
              onClick={() => {
                onReject(original);
              }}
            >
              <Tooltip title="Reject">
                <CloseSquareOutlined />
              </Tooltip>
            </Button>

            {/*  */}
            {original.alreadyHasAccount === 0 ? (
              <Popconfirm
                title="Are you sure the merchant details are correct?"
                description="Please review the information carefully before proceeding."
                onConfirm={() => {
                  onApproved(original);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={formLoading} size="small" type="text">
                  <Tooltip title="Approve">
                    <CheckSquareOutlined />
                  </Tooltip>
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={() => onRetainOldAccoumt(original)}
                disabled={formLoading}
                size="small"
                type="text"
              >
                <Tooltip title="Retain old account">
                  <CheckSquareOutlined />
                </Tooltip>
              </Button>
            )}
          </>
        ),
        width: 100,
      },
      {
        Header: "Status",
        // accessor: "alreadyHasAccount",
        accessor: (row) => {
          if (row.alreadyHasAccount === 1) {
            return (
              <Tag color="tomato" style={{ fontSize: 14 }}>
                old account
              </Tag>
            );
          } else {
            return (
              <Tag color="#3CB371" style={{ fontSize: 14 }}>
                new account
              </Tag>
            );
          }
        },
      },
      {
        Header: "Merchant Name",
        accessor: "name",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Address",
        accessor: "address",
      },

      {
        Header: "Coordinates",
        accessor: (row) => {
          return row.coordinates ? row.coordinates : "pending";
        },
      },

      {
        Header: "Featured Image",
        accessor: (row) => {
          return row.merchant_logo ? (
            <Image
              width={50}
              src={`https://groceria-storage.sgp1.digitaloceanspaces.com/assets/images/partner-merchant-featured-image/${row.featured_image}`}
            />
          ) : (
            "pending"
          );
        },
      },

      {
        Header: "Merchant Logo",
        accessor: (row) => {
          return row.merchant_logo ? (
            <Image
              width={50}
              src={`https://groceria-storage.sgp1.digitaloceanspaces.com/assets/images/merchants/${row.merchant_logo}`}
            />
          ) : (
            "pending"
          );
        },
      },
      {
        Header: "Tags",
        accessor: (row) => {
          if (Array.isArray(row.merchant_tags)) {
            const tagNames = row.merchant_tags.map((tag) => tag.tag).join(",");

            return tagNames;
          }

          return "pending";
        },
      },

      {
        Header: "Rejection Note",
        accessor: (row) => {
          return row.note !== "null" ? row.note : "";
        },
      },

      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY hh:mm A")
            : "--";
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchMerchantsForApproval(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (closeloading) {
      dispatch(fetchMerchantsForApproval(defaultPage));
      setCloseLoading(false);
    }
  }, [dispatch, defaultPage, closeloading]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={merchantsForApprovalData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <RejectMerchantModal />
            <OldAccountMOdal
              oldmerchantName={newMerchant}
              selectedNewMerchantId={newMerchantId}
            />
          </>
        }
      />
    </>
  );
};

export default MerchantsForApproval;
